<template>
  <div
    class="navbar-container d-flex content align-items-center"
  >
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li>
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <dark-Toggler class=" d-lg-block" />
    </div>

    <div
      class="dropdown-header d-flex"
      style="cursor:pointer;"
      @click="goToCart()"
    >
      <h4 class="notification-title mb-0 mr-auto">
        <feather-icon
          icon="ShoppingCartIcon"
          size="21"
        />
      </h4>
      <b-badge
        v-if="items.length > 0"
        pill
        variant="light-success"
      >
        {{ items.length }}
      </b-badge>
    </div>

    <b-navbar-nav
      v-if="user"
      class="nav align-items-center ml-auto"
    >
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav color_p">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.name }}
            </p>
            <span
              v-if="user.role == 'ADMIN'"
              class="user-status"
            >
              Administrador
            </span>
            <!--
            <span
              v-if="user.role == 'PAID'"
              class="user-status"
            >
              PAGO
            </span>
            -->
            <span
              v-if="user.role == 'SECRETARY'"
              class="user-status"
            >
              Secretario
            </span>
            <span
              v-if="user.role == 'SPEAKER'"
              class="user-status"
            >
              Palestrante
            </span>
            <span
              v-if="user.role == 'COMMON'"
              class="user-status"
            >
              Usuario
            </span>
            <span
              v-if="user.role == 'TREASURER'"
              class="user-status"
            >
              Tesoureiro
            </span>
            <span
              v-if="user.role == 'PRESIDENT'"
              class="user-status"
            >
              Presidente
            </span>
            <span
              v-if="user.role == 'MASTER'"
              class="user-status"
            >
              Administrador geral
            </span>
            <span
              v-if="user.role == 'SPONSOR'"
              class="user-status"
            >
              Patrocinador
            </span>
            <span
              v-if="user.role == 'VICE-PRESIDENT'"
              class="user-status"
            >
              Vice-presidente
            </span>
            <span
              v-if="user.role == 'SECRETARY-GENERAL'"
              class="user-status"
            >
              Secretario Geral
            </span>
            <span
              v-if="user.role == 'SCIENTIFIC-DIRECTOR'"
              class="user-status"
            >
              Diretor cientifico
            </span>
            <span
              v-if="user.role == 'COURSE-DIRECTOR'"
              class="user-status"
            >
              Diretor de cursos
            </span>
            <span
              v-if="user.role == 'COMMUNICATION-DIRECTOR'"
              class="user-status"
            >
              Diretor de comunicação
            </span>
            <span
              v-if="user.role == 'CONGRESS-ADMINISTRATOR'"
              class="user-status"
            >
              Administrador do congresso
            </span>
            <span
              v-if="user.role == 'PROFESSOR'"
              class="user-status"
            >
              Professor
            </span>
            <span
              v-if="user.role == 'ADVISOR'"
              class="user-status"
            >
              Orientador
            </span>
            <span
              v-if="user.role == 'SPONSOR-COMMUNICATION'"
              class="user-status"
            >
              Patrocinador de comunicação
            </span>
            <span
              v-if="user.role == 'CONGRESS-SPONSOR'"
              class="user-status"
            >
              Patrocinador do congresso
            </span>
            <span
              v-if="user.role == 'JURIDICAL'"
              class="user-status"
            >
              Juridico
            </span>
            <span
              v-if="user.role == 'STATE-PRESIDENT'"
              class="user-status"
            >
              Presidente da estadual
            </span>
            <span
              v-if="user.role == 'ART-MANAGER'"
              class="user-status"
            >
              Design chefe
            </span>
            <span
              v-if="user.role == 'SNNO-ASSOCIATE'"
              class="user-status"
            >
              Associado SNNO
            </span>
          </div>
          <b-avatar
            v-if="sessionInfo.facebook == false && sessionInfo.google == false"
            size="40"
            variant="light-primary"
            badge
            :src="`${baseApi}/storage/image/${user.photo}`"
            class="badge-minimal"
            badge-variant="success"
          />
          <b-avatar
            v-if="sessionInfo.facebook == true || sessionInfo.google == true"
            size="40"
            variant="light-primary"
            badge
            :src="sessionInfo.photo"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          v-if="user.role == 'ADMIN' || user.role == 'MASTER'"
          link-class="d-flex align-items-center"
          :to="{ name: 'admin' }"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Administrador</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'userProfile' }"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Meu perfil</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'Certificate' }"
        >
          <feather-icon
            size="16"
            icon="FileIcon"
            class="mr-50"
          />
          <span>Certificados</span>
        </b-dropdown-item>

        <b-dropdown-divider />
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Sair</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useJwt from '@/auth/jwt'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_API_BASE
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    sessionInfo() {
      return this.$store.state.auth.sessionInfo
    },
    items() {
      return this.$store.state.payment.items
    }
  },
  methods: {
    goToCart() {
      window.location.href = '/pagamento'
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Redirect to login page
      this.$router.push({ name: 'login' })
    },
  }
}
</script>
<style scoped>
  .color_p{
    color: #8d939d !important;
  }
  .header-navbar[class*=bg-] .navbar-nav .nav-item > a span {
    color: #8d939d !important;
}
</style>
